

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
    font-family : 'Noto Sans KR';
    
}
button:hover {
  cursor:pointer;
}
input::placeholder, textarea::placeholder {
  color:#000000;
}
h3 {
    font-family : 'Noto Sans KR';
    letter-spacing: -.8px;
}
p {
  font-family : 'Noto Sans KR';
    letter-spacing: -.8px;
}

textarea { resize: none; }

a {
  list-style: none;
  text-decoration: none;
}

th, td {
  text-align: center;
  height: 50px;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.wayImage, .wayImage > img {
  overflow: hidden;
}
.wayImage > img:hover {
  transform:scale(1.2);        
  transition: transform .5s;
  overflow: hidden;
}

input, .required, textarea.required {
  background-image: url(../src/img/require.png) !important;
  background-repeat: no-repeat;
  padding-left: 24px ;
  background-position: 6px 50%;
}


/* 추가된 부분 */
#line-wrapper {
  cursor:pointer;
  /* width:100%;
  height:100%; */
  position:relative;
  display: flex;
  flex-direction: column;
  gap:5px;
  
}

/* 추가된 부분 */
.line {
  background:#ffffff;
  /* margin-top:6px; */
  /* margin-bottom:6px; */
  width:35px;
  height:5px; 
  border-radius:2px;
  box-shadow:0 1px 3px rgba(0,0,0,.5);
  position:relative;
}
